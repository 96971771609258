<template>
  <div class="hasoneTooneComponents">
    <div>
      <p class="company_head">您的行业顾问已上线，快来联系吧</p>
      <div class="person-wrap" v-loading="loading">
        <div
            v-for="(item, index) in affCompanyList"
            :key="index"
            class="company_related"
            v-show="index < num"
        >
          <p>
            <el-image
                @click="showCard(item)"
                :src="item.avatar ? item.avatar : defaultAvatar"
                class="cursor"
                style="width: 60px; height: 60px; border-radius: 50%"
                :fit="'scale-down'"
            >
            </el-image>
          </p>
          <div class="overText">
            <p class="company_name textOverflow">
              <span
                  class="name"
                  :title="
                  item.user_name_en
                    | priorFormat(item.user_name_zh, LOCALE)
                    | textFormat
                "
              >{{
                  item.user_name_en
                      | priorFormat(item.user_name_zh, LOCALE)
                      | textFormat
                }}</span
              >
              <span
                  class="companyName textOverflow"
                  :title="
                  item.company_name_en
                    | priorFormat(item.company_name_cn, LOCALE)
                    | textFormat
                "
              >{{
                  item.company_name_en
                      | priorFormat(item.company_name_cn, LOCALE)
                      | textFormat
                }}</span
              >
            </p>
            <div class="company_enquiry">
              <span class="btn"  :class="[item.user_id == USER_INFO.id?'stop':'']"  @click="order(item)">
                <!-- <el-image
                    class="hearticon"
                    style="width: 12px; height: 12px"
                    :src="clear"
                    fit="scale-down"></el-image> -->
                预约沟通
              </span>
            </div>
          </div>
        </div>
        <noDataImg v-if="affCompanyList.length === 0"></noDataImg>
      </div>
    </div>
    <orderFreeDialog :orderVisible="orderVisible" @orderVisibleShow="orderVisibleShow" @dataChange="chatInvite" title="预约沟通"/>

  </div>
</template>

<script>
import orderFreeDialog from "@/baseComponents/orderFreeDialog";
export default {
  name: "hasoneToone",
  components:{
    orderFreeDialog
  },
  data() {
    return {
      num: 3,
      affCompanyList: [],
      companyInfo: {
        in_user_id: "",
        in_company_id: "",
      },
      loading: false,
      defaultAvatar: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/avatar.png",
      clear:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/clear.png",
      orderVisible:false,
      currentData:{}
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    showCard(e) {
      let row = e;
      this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
    },
    async getList(){
      this.loading = true;
      let data = await this.$store.dispatch(
          "API_company/checkNewCompanyList",
          {
            start: 0,
            limit: 3,
            source:this.PJSource
          }
      );
      this.affCompanyList = data.data;
      this.loading = false;
    },
    handleEnterLaunchOneToOne(item) {
      this.companyInfo = Object.assign({}, item);
    },
    order(item){

      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }else if(this.USER_INFO.company_bind_status==0|| !this.USER_INFO.company_id){  //没有绑定公司
          this.$message.warning(this.$t('company_information'))
      }else{
        if (item.user_id === this.USER_INFO.id) {
          return 
        }
        this.orderVisible=true
        this.currentData=item
      }
    },
    orderVisibleShow(val){
      this.orderVisible=false
    },
    async chatInvite(val){
      let {start_time,end_time,day,send_postscript}=val
      let params = {
        send_user_id: this.USER_ID,
        send_company_id: this.USER_INFO.company_id,
        in_user_id: this.currentData.user_id,
        in_company_id: this.currentData.company_id,
        daily_source: 2,
        related_id: this.USER_INFO.company_id,
        start_time,
        end_time,
        day,
        send_postscript,
        source:this.PJSource
      };
      let res = await this.$store.dispatch("API_company/companyInvite", params);
      if (res.success) {
        this.$message.success(this.$t("sendSuccess"));
        this.orderVisible = false
      } else if (res.errorcode == 1000) {
        this.$message.warning(this.$t("repeatTheBooking"))
      } else if (res.errorcode == 1017) {
        this.$message.warning(this.$t("companyCatalog1v1Error2"))
      } else if (res.errorcode == 1018) {
        this.$message.warning(this.$t("companyCatalog1v1Error3"))
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .waitTips {
  /deep/ .el-message-box__content {
    color: #f00;
  }
}
* {
  word-wrap: break-word;
}
.hasoneTooneComponents {
  width: 100%;
  word-wrap: break-word;
  position: relative;
  margin-bottom: 20px;
  position: relative;
}

.company_head {
  font-size: 16px;
  font-weight: 800;
  color: #333333;
}

.company_related {
  display: flex;
  margin-top: 20px;

  .overText {
    margin-left: 16px;
    width: 205px;
    p.company_name {
      display: flex;
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .name{
        font-size: 14px;
        font-weight: 800;
        color: #333333;
        margin-right: 10px;
      }
      .companyName{
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        color: #909399;
      }
    }

    .company_enquiry {
      margin-top: 15px;
      .btn{
        cursor: pointer;
        width: 100px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid;
        // border-image: linear-gradient(90deg, rgba(255, 153, 102, 1), rgba(255, 94, 98, 1)) 1 1;
        padding: 5px 14px;
        font-size: 12px;
        font-weight: 400;
        color: #0D6BB9;
        .hearticon{
          margin-right: 2px;
          position:relative;
          top: 1px;
        }
      }
      .stop{
        cursor:not-allowed;
      }
    }
  }
}

.cd_sub_title {
  margin-top: 5px;
  color: #1890ff;
  font-size: 14px;
  height: 20px;
  font-weight: bold;
}

.sub_title_zh {
  max-width: 56px;
}

.sub_title_en {
  max-width: 120px;
}

.company_related:nth-child(4n) {
  margin-bottom: 34px;

  .el-divider--horizontal {
    display: none;
  }
}

.text_pointer {
  cursor: pointer;
}

.overText .el-divider--horizontal {
  margin: 16px 0px 17px 0px;
}
.person-wrap{
  min-height:200px;
  position: relative;
}

</style>
